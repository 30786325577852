// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
// console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>
// console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

// hotwire + stimulus + font + stylesheet
import "@hotwired/turbo-rails"
import "~/controllers/stimulus.js"
import "@fontsource-variable/inter"
import "~/stylesheets/application.css"

// document.addEventListener("turbo:click", function () {
//     console.log('TURBO:CLICK')
// })
// document.addEventListener("turbo:before-visit", function () {
//     console.log('TURBO:BEFORE-VISIT')
// })
// document.addEventListener("turbo:visit", function () {
//     console.log('TURBO:VISIT')
// })
// document.addEventListener("turbo:submit-start", function () {
//     console.log('TURBO:SUBMIT-START')
// })
// document.addEventListener("turbo:before-fetch-request", function () {
//     console.log('TURBO:BEFORE-FETCH-REQUEST')
// })
// document.addEventListener("turbo:before-fetch-response", function () {
//     console.log('TURBO:BEFORE-FETCH-RESPONSE')
// })
// document.addEventListener("turbo:submit-end", function (event) {
//     console.log('TURBO:SUBMIT-END')
//     // event.detail
// })
// document.addEventListener("turbo:before-cache", function () {
//     console.log('TURBO:BEFORE-CACHE')
// })
// document.addEventListener("turbo:before-stream-render", function () {
//     console.log('TURBO:BEFORE-STREAM-RENDER')
// })
// document.addEventListener("turbo:render", function () {
//     console.log('TURBO:RENDER')
// })

document.addEventListener("turbo:morph", function () {
    console.log('TURBO:MORPH')
})