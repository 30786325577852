import { Application } from "@hotwired/stimulus"
import { registerControllers } from "stimulus-vite-helpers" // automatically load controller files https://github.com/ElMassimo/stimulus-vite-helpers

const application = Application.start()
const controllers = import.meta.glob("./**/*_controller.js", { eager: true })

// configure stimulus development experience
application.debug = false
window.Stimulus   = application

registerControllers(application, controllers)

// third-party controllers
import Reveal from "stimulus-reveal-controller"
import PasswordVisibility from "@stimulus-components/password-visibility"
import Dropdown from "@stimulus-components/dropdown"
import AutoSubmit from "@stimulus-components/auto-submit"
application.register("reveal", Reveal)
application.register("password-visibility", PasswordVisibility)
application.register("dropdown", Dropdown)
application.register("auto-submit", AutoSubmit)