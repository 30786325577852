import { Controller } from "@hotwired/stimulus"
import Uppy, { debugLogger } from "@uppy/core";
import Dutch from '@uppy/locales/lib/nl_NL';
import DragDrop from "@uppy/drag-drop";
import StatusBar from "@uppy/status-bar";
import Tus from "@uppy/tus";
import "@uppy/core/dist/style.css";
import "@uppy/drag-drop/dist/style.css";
import "@uppy/status-bar/dist/style.css";

export default class extends Controller {
    static values = {
        userId: Number
    }
    connect() {
        this.uppy = new Uppy({ logger: debugLogger, locale: Dutch, onBeforeFileAdded: () => true, })
            .use(DragDrop, {
                target: "#dragdrop",
                locale: {  strings: {
                        dropHereOr: 'Sleep één of meer bestanden in dit vlak of %{browse}',
                        browse: 'blader',
                    }},
            })
            .use(StatusBar, { target: "#statusbar" })
            .use(Tus, {
                endpoint: '/files',
                chunkSize: 5*1024*1024, // chunking is required unless you're using Falcon
                headers: { "User-Id": this.userIdValue },
            });

        this.uppy.on("complete", (result) => {
            if (result.failed.length === 0) {
                console.log("Upload successful üòÄ");
            } else {
                console.warn("Upload failed üòû");
            }
            console.log("successful files:", result.successful);
            console.log("failed files:", result.failed);
        });
    }

    disconnect() {
        this.uppy.close();
    }
}